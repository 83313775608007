<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t("contactSupport") }}
      </v-card-title>
      <v-card-text>
        <v-alert type="info" id="alert" prominent elevation="5">
          {{ $t("somthingNotWorking") }}
          <a
            class="email"
            href="mailto:koncernit@rigspolitiet.dk"
            v-if="isRigspolitiet"
          >
            koncernit@rigspolitiet.dk
          </a>
          <a class="email" href="mailto:support@framweb.no" v-else>
            support@framweb.no
          </a>
        </v-alert>
        <p v-if="!isRigspolitiet">
          {{ $t("orCallUs") }}
          <a href="tel:+47 56 90 14 80">+47 56 90 14 80</a>
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import store from "@/store";

export default {
  name: "Feedback",

  data: () => ({
    text: ""
  }),

  computed: {
    isRigspolitiet() {
      // Not ready for this yet - Waiting for more content
      return false;
      //return store.state.userProfile?.Kundenr === 32622;
    }
  }
};
</script>
<style scoped>
#alert {
  font-size: 22px;
}
.email {
  color: white;
}
</style>
